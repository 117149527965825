.Modal {
    position: fixed;
    z-index: 450500;
    background-color: white;
    width: 90%;
    min-width: 350px;
    box-shadow: 1px 1px 1px black;
    padding: 0px;
    border-radius:3px;
    left: 5%;
    /*top: 30%;*/
    margin-top:auto;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
}

.Modal-Wrapper {
    height:100vh;
    width:100vw;
    position: fixed;
    z-index: 10000000005;
    background-color: rgba(32, 32, 32,0.8);
}

@media (min-width: 600px) {
    .Modal {
        top:'10%';
        width: 500px;
        left: calc(50% - 250px);
    }
}