.c {       
    position: absolute;
    /* left: 50%;
    transform: translate(-50%, 0); */
    display: flex;
    align-items: center;
    justify-content: center;  
    max-width: 500px;
    max-height: 500px;
    /* width: 350px;
    height: 200px; */
    top:0px;
    bottom:0px;
    /*left:0px;
    right:0px;*/
    width:100%;
    cursor: pointer;
    will-change: transform, opacity;
  }
  
  .front,
  .back {
    background-size: cover;
    width: 100%;
  }
  
  .back {
    /* background-image: url(https://images.unsplash.com/photo-1544511916-0148ccdeb877?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1901&q=80i&auto=format&fit=crop); */
  }
  
  .front {
    /* background-image: url(https://images.unsplash.com/photo-1540206395-68808572332f?ixlib=rb-1.2.1&w=1181&q=80&auto=format&fit=crop); */
  }
  
  .container {
    display: flex;
    align-items: center;
    height: 100%;
    width:100%;
    justify-content: center;
    position:relative;
  }