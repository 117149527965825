.button-1{
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    padding: 8px 20px;
    margin: 3px;
    background: rgb(238, 238, 238);
    box-shadow: rgb(10 10 10 / 20%) 0px 0px 2px;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    color: rgb(51, 51, 51);
}

.button-1:hover{
    background:#ffffff;
}

.button-2{
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    padding: 8px 20px;
    margin: 3px;
    background: #05ffac;
    box-shadow: rgb(10 10 10 / 20%) 0px 0px 2px;
    border: 1px solid rgb(22, 163, 34);
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    color: rgb(51, 51, 51);
}

.button-2:hover{    
    background: rgb(5, 240, 95);
}

.button-3{
    font-size: 14px;
    font-weight: bold;
    padding: 8px 20px;
    margin: 3px;
    background: rgb(255, 69, 1);
    box-shadow: rgb(10 10 10 / 20%) 0px 0px 2px;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    color: rgb(255, 255, 255);
}

.button-3:hover{    
    background: rgb(240, 79, 5);
}

.view-header{
    /*background: rgb(238, 238, 238);
    border: 1px solid rgb(204, 204, 204);*/
    height: 50px;
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;/* 
    background-color:#e8fff8;
    box-shadow:0px 2px 5px rgba(40, 40, 40, 0.4) */
}

.view-header-title{
    padding: 5px 15px;
    font-size: 16px;
    /*font-weight: bold;*/
    color: rgb(51, 51, 51);
    color:#003c36;
    white-space: nowrap;
}

.view-container{
    padding:5px;
    box-sizing: border-box;
    max-width:700px;
    display: flex;
    flex-direction: column;
    align-content: center;
    overflow: hidden;
}


.form-wrapper {
    min-height: 300px;
    width: 100%;
    max-width: 400px;
    min-width: 350px;
    background: none;/* rgba(255, 246, 246,0.7);*/
    /* box-shadow: 2px 2px 6px rgb(46 46 46 / 80%); */
    border-radius: 15px;
    padding: 20px 20px;
    margin: 10px;
    /* background-image: url(../assets/images/wrapper-background-2.svg); */
    background-repeat: no-repeat;
    background-size: 100%;
    box-sizing: border-box;
}

.form-title {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    padding-bottom: 20px;
    text-transform: uppercase;
}

.text-input {
    width: 100%;
    padding: 8px;
    margin: 10px 0px;
    box-sizing: border-box;
    /* border-radius: 5px; */
    border: 0px solid #828282;
    background: #f5f5f5;
    border-bottom: solid 1px #006838;
    font-size: 14px;
    color: #333333;
}



.text-input-label {
    /*font-weight: bold; */
    font-size: 16px;
    color: #161616;
}

.start-button{
    padding: 10px 50px;
    letter-spacing: 5px;
    background: #56BE8F;
    color: white;
    font-size: 16px;
    border: 0px;
    border-radius: 20px;
    cursor: pointer;
}

.flat-button{
    padding: 10px 40px;
    background: #05ffac;
    color: #04223a;
    font-size: 14px;
    border: 0px;
    border-radius: 20px;
    cursor: pointer;
}

.flat-button-2{
    padding: 8px 40px;
    background: #7affd3;
    color: #111513;
    font-size: 14px;
    border: 1px solid #98f1c8;
    border-radius: 20px;
    cursor: pointer;
    font-family: 'Righteous';
    box-shadow: 1px 1px 3px rgb(10 10 10 / 76%);

}

.game-play-info-header
{
    background: rgb(22 39 51);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 65px;
    padding: 10px;
    box-shadow: rgb(10 10 10 / 50%) 0px 2px 2px;
}

.text-input{
    box-sizing: border-box;
    box-shadow: rgb(10 10 10 / 50%) 2px 2px 4px;
    background: rgb(238, 238, 238);
    padding: 5px 10px;
    outline: none;
    height: 50px;
    border: 0px solid rgb(204, 204, 204);
    width: 100%;
}

.primary-text-input{
    font-size: 26px;
    max-width: 300px;
    color: rgb(68, 175, 126);
    text-align: center;
    box-shadow: rgb(20 20 20 / 30%) 2px 2px 2px;
    padding: 10px;
    border-width: 1px 1px 2px;
    border-style: solid;
    border-color: rgb(238, 238, 238) rgb(238, 238, 238) rgb(68, 175, 126);
    border-image: initial;
    outline: none;
    font-weight: bold;
}

.numbering-style-01{
    padding: 10px;
    height: 40px;
    width: 40px;
    background: #2abea5;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    padding: 10px;
    text-align: center;
    align-items: center;
    margin: 5px;
    margin-right: 20px;
    box-shadow: inset 2px 2px 2px rgb(100 100 100 / 80%);
}