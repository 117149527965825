@keyframes showNotification{
    from {left: -300px; opacity: 0.2;}
    to {left: 0px;opacity: 1;}
}

.notification-container{
    width: 100%;
    max-width: 400px;
    position: fixed;
    bottom:20px;
    right:20px;
    display: flex;
    flex-direction: column;
    /*background-color: rgb(255, 153, 0);*/
    color: rgb(255, 255, 255);
    min-height: 30px;
    align-items: center;
    z-index: 100;
    
}

.notification-wrapper{
    animation-name: showNotification;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
}

.error-notification{
    min-height: 20px;
    /* margin: 5px; */
    width: 90%;
    background-color:rgba(250,0,0,0.9);
    color: rgb(255 255 255);
    border-top: 5px solid  rgba(10,10,10, 1.0);
    font-size: 14px;
    box-sizing: border-box;
    padding: 10px;
    position: relative;
    margin: 5px;
    box-shadow: 1px 1px 5px rgb(20 20 20 / 40%);
    border-radius: 5px;
    /* left: -200px;*/
}

.success-notification{
    min-height: 20px;
    /* margin: 5px; */
    width: 90%;
    background-color: green;
    color: rgb(255 255 255);
    border-top: 5px solid rgba(10,10,10, 1.0);
    font-size: 14px;
    box-sizing: border-box;
    padding: 5px 10px;
    position: relative;
    margin: 5px;
    box-shadow: 1px 1px 5px rgb(20 20 20 / 40%);
    border-radius: 5px;
    /* left: -200px;*/
}

.notification-message{
    font-size: 12px;
    /*border-top: dotted #797979 1px;*/
    padding: 5px 0px;
}

.notification-title{
    /*font-style: italic; */
    font-size:14px;  
    /*padding: 5px 0px;*/
}

.notification-close-button{
    height: 15px;
    width: 15px;
    position: absolute;
    background-color: rgba(10,10,10,0.6);
    right: 10px;
    top: 10px;
    border-radius: 50%;
    cursor: pointer;
}