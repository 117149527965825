.App {
 /*  text-align: center; */
  /*background-color: #3c4b52;*/
  /* background: linear-gradient(135deg, rgba(2,0,36,1) 0%, rgba(9,121,116,1) 35%, rgba(0,255,111,1) 100%);*/
} 

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.game-list-link{
  color: rgb(0 4 3);
    background-color: rgb(160 234 210);
    /* box-shadow: 2px 2px 2px rgb(100 100 100 / 50%); */
    padding: 5px 20px;
    /* font-weight: bold; */
    border-radius: 20px;
    text-decoration: none;
    font-size: 13px;
    letter-spacing: 1px;
}

.game-list-link:hover{
  background-color: rgb(5, 255, 172);
  box-shadow:0px 0px 0px 5px rgba(250,250,250,0.4);
}

.game-ok-button{
  width: 48px;
    border: 1px solid green;
    background: white;
    padding: 10px;
    margin: 0px;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 10px rgb(250 250 250 / 20%);
}


.game-cancel-button{
  width: 48px;
    border: 1px solid red;
    background: white;
    padding: 10px;
    margin: 0px;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 10px rgb(250 250 250 / 20%);
}


