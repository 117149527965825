.ticker {
    /* position: absolute; */
    /* max-height: 4rem;
    max-width: 4rem; */
    position: relative;
    width: 3.5vw;
    height: 3.5vw;
    width: 60px;
    height: 60px;
    /* bottom: 2rem;
    left: 2rem; */
  }
  
  .ticker > * {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .ticker > div {
    opacity: 0.9;
    border: solid 6px rgb(218, 216, 216);
    display: flex;
    justify-content: center;
  }

  .ticker > span {
    font-size:11px;
    align-self: center;    
  }
  
  .ticker > svg {
    fill: none;
    stroke: rgb(255, 146, 4);
    stroke-width: 2;
    stroke-linecap: round;
    stroke-dasharray: 120;
    stroke-dashoffset: 120;
  }