.navigation-bar{
    width: 100%;
    height: 60px;
    background-color: #ffffff;

    align-items: center;
    justify-content: space-between;
    display: flex;
    position: fixed;
    z-index: 20;
    /*padding: 5px;*/
    /* background: linear-gradient(135deg, rgb(150, 251, 196) 0%, rgb(249, 245, 134) 100%); */
    background: linear-gradient(135deg, rgb(227, 255, 240) 0%, rgb(254, 253, 210) 100%);
    border-bottom: 0px solid #ED1C24;
    box-shadow: 0px 2px 2px rgb(13 13 13 / 10%);

    box-sizing: border-box;

}

.navigation-bar > div{
    display:flex;
    align-items:center
}

.navigation-bar-button-1{
    outline: none;
    margin: 2px;
    font-size: 18px;
    color: #0f75bc;
    background: none;
    padding: 5px 20px;
    border: 0px;
    cursor: pointer;
}

.navigation-bar-button-2{
    border: 0;
    box-sizing: border-box;
    padding: 11px 24px;
    display: inline-block;
    font-size: 17px;
    border-radius: 30px;
    line-height: 18px;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center;
    transition: opacity .25s,background-color .25s,color .25s,border .25s,box-shadow .25s;
    outline-style: none;
    font-family: TTNorms-Medium,Arial,sans-serif;
    font-weight: 500;
    background-color:#0f75bc;
    background-color:#f68a44;
    flex-wrap: nowrap;
    color: white;
    box-shadow: 1px 1px 2px rgba(100,100,100,0.4);
}

.app-title{
    font-weight: bold;
    color: #555555;
    /*font-style:italic;*/
    text-decoration: none;
    padding:2px 20px;

    max-width: 260px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    font-weight: bold;
    text-shadow: rgb(255 255 255 / 30%) 0px 1px 0px, rgb(0 0 0 / 50%) 0px -1px 0px;
    color: rgb(204, 103, 0);
    font-family: "Dancing Script";
    font-size: 30px;
}

@media screen and (max-width: 550px) {

    .app-title{
        max-width: 140px;
        font-size: 20px;  
    }
}

.app-logo {
    height: 60%;
    padding: 2px 5px;
    /*pointer-events: none;*/
    user-select: none;
    cursor: pointer;
    background:none;
    align-items: center;
    display: flex;
}

.app-logo:hover{
    background: rgba(150,150,150,0.2);
    border-radius: 5px;
}

.user-info-avartar{
    margin-right: 10px;
    height: 30px;
    width: 30px;
    background-color: rgb(5, 255, 172);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
}

.user-info-avartar:hover{
    background-color: #08e966;
}

.user-info-menu{
    position: absolute;
    min-width: 100px;
    min-height: 30px;
    color: #0f75bc;
    background: #FFFFFF;
    box-shadow: 2px 2px 5px rgb(30 30 30 / 30%);
    top: 50px;
    font-size: 15px;
    border-radius: 5px;
    padding: 10px 15px;
}
.user-info-menu > ul{
   list-style: none;
}
.user-info-menu > ul > li{
    white-space: nowrap;
    cursor: pointer;
    margin-bottom:5px;
    margin-top:5px;
    padding:5px 10px;
}

.user-info-menu > ul > li:hover{
    background-color: #EEEEEE;
    border-radius: 5px;
}
.user-info-wrapper{
    margin-right: 10px;
}

.user-info-name{
    font-size: 16px;
    color: #333333;
    max-width: 260px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.user-info-email{
    font-size: 11px;
    padding-top: 2px;
    color: #555555;
}


/* Side Menu Button */
.side-menu-button-bar{
    display: inline-block;
    margin-bottom: 6px;
    height: 2px;
    background-color: #088b5a;
    width: 24px;
}

.side-menu-button-bar-1{   
    margin-bottom: 6px;    
}

.side-menu-button-bar-2{   
    margin-bottom: 6px;  
    width:70%;  
}

.side-menu-button-bar-3{   
    margin-bottom: 1px;  
    width:30%; 
}

.side-menu-button{
    display: none;
    flex-direction: column;
    margin: 5px 5px 5px 10px;
    cursor: pointer;
    background:none;
    padding: 10px;
    border-radius: 5px;

    padding: 6px;
    border-radius: 5px;
    width: 36px;
    height: 36px;
    box-sizing: border-box;
}



@media screen and (max-width: 550px) {

    .navigation-bar{
        width: 100%;
        height: 60px;   
    }

    .side-menu-button-active{
        background: #05ff8a;
        padding: 0px;
    }

    .side-menu-button-bar-1-active{   
        margin:0px;    
        transform:rotate(-45deg) translate(-8px, 16px);
        width:24px;
        transition: all .3s ease-in;
    }
    
    .side-menu-button-bar-2-active{   
        display:none;
    }
    
    .side-menu-button-bar-3-active{     
        margin:4px;    
        transform:rotateZ(45deg) translate(8px, 7px);
        width:24px;
        transition: all .3s ease-in;
    }

    .side-menu-button{
        display: flex;
    }
}