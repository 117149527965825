.characterWrapper
{
    border-radius: 5px;
    display: flex;
    box-shadow: 1px 1px 10px rgb(153 153 153 / 70%);
    background:white;    
    /* height: 60px; */
    padding: 3px;
    width: 100%;
    justify-content:center;
    align-items:center;
    box-sizing:border-box;
    aspect-ratio:1/1;
    margin:5px; 
    font-family:'Righteous';
    cursor:pointer;
    /* border:1px solid #ACACAC; */
}